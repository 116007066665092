/* common styles for project container regardless of screen size */
.projects-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 50px;
  padding: 1em;
  box-sizing: border-box;
}

.projects-header {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  padding-bottom: 5px;
}

.project-img-container {
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 1em;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  width: 100%;
  box-sizing: border-box;
}

.project-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
}

.project-img {
  width: 100%;
  height: 320px;
  border-radius: 5px;
  box-sizing: border-box;
  transition: .3s ease-in-out;
}

.project-img:hover {
  cursor: pointer;
  transform: scale(1.05);
  overflow: hidden;
  box-sizing: border-box;
}

.project-img-container-mobile {
  width: 100%;
  height: 20rem;
  box-sizing: border-box;
}

.project-img-mobile {
  width: 100%;
  height: 20rem;
  border-radius: 5px;
  box-sizing: border-box;
  transition: .3s ease-in-out;
}

.project-technology-light {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  color: black;
}

.project-technology-dark {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.project-description {
  margin: 5px 0px 0px 0px;
}

.project-title-dark {
  color: var(--light-font-color-hover)
}

.project-title-light {
  color: var(--light-font-color-hover)
}


.project-subtitle {
  margin-bottom: 10px;
}

.project-technology-light:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.project-technology-dark:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.project-link:hover {
  cursor: pointer;
  opacity: 0.8;
}

.project-title-light {
  margin-top: 0px;
  margin-bottom: 10px;
}

.project-title-dark {
  margin-bottom: 10px;
  margin-top: 0px;
}

.project-icon {
  padding-left: 10px;
}

/* Small devices (landscape phones, 575.99px and down) */
@media (max-width: 575.99px) {
  .projects-container {
    padding: 1em;
  }

  .project-img-container {
    display: none;
  }

  .project-img {
    display: none;
  }

  .project-details {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px through 767.99px) */
@media (min-width: 576px) and (max-width: 767.99px) {
  .projects-container {
    width: 100%;
    padding: 2em;
  }

  .project-img-container {
    display: none;
  }

  .project-img {
    display: none;
  }

  .project-details {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px through 991.99px) */
@media (min-width: 768px) and (max-width: 991.99px) {
  .projects-container {
    width: 85%;
    margin-left: 7.5%;
  }

  .project-img-container {
    width: 60%;
  }

  .project-img-container-mobile {
    display: none;
  }

  .project-img-mobile {
    display: none;
  }

  .project-details {
    padding-left: 1em;
    width: 40%;
  }
}

/* Large devices (desktops, 992px through 1199.99px) */
@media (min-width: 992px) and (max-width: 1199.99px) {
  .projects-container {
    width: 80%;
    margin-left: 10%;
  }

  .project-img-container {
    width: 50%;
  }

  .project-img-container-mobile {
    display: none;
  }

  .project-img-mobile {
    display: none;
  }

  .project {
    justify-content: space-between;
  }

  .project-details {
    padding-left: 1em;
    width: 50%;
  }
}

/* Extra large devices (large desktops, 1200px through 1599.99px) */
@media (min-width: 1200px) and (max-width: 1599.99px) {
  .projects-container {
    width: 75%;
    margin-left: 15%;
  }

  .project-img-container {
    width: 50%;
  }

  .project-img-container-mobile {
    display: none;
  }

  .project-img-mobile {
    display: none;
  }

  .project {
    justify-content: space-between;
  }

  .project-details {
    padding-left: 1em;
    width: 50%;
  }
}

/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
  .projects-container {
    width: 70%;
    margin-left: 15%;
  }

  .project-img-container {
    width: 50%;
  }

  .project-img-container-mobile {
    display: none;
  }

  .project-img-mobile {
    display: none;
  }

  .project {
    justify-content: space-between;
  }

  .project-details {
    padding-left: 1em;
    width: 50%;
  }
}
