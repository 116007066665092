/* common styles for about container regardless of screen size */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
  padding: 1em;
  box-sizing: border-box;
}

.about-header {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: left;
  width: 100%;
  margin: 0px 0px 20px 0px;
  padding-bottom: 5px;
}

.about-subheader {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.about-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  margin: 20px 0px 20px 0px;
}

.about-detail {
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin: 7.5px 0px 7.5px 0px;
}

.about-detail-floated {
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin: 0px 0px 7.5px 0px;
  padding: 0px;
}

.about-img-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-img-floated {
  width: 250px;
  display: block;
  float: left;
  margin-right: 1em;
  border-radius: 5px;
}

.about-img {
  border-radius: 5px;
  margin-bottom: 1rem;
  object-fit: cover;
}

.about-img-music {
  background-color: white;
  border-radius: 5px;
  padding: 7px;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.about-img-team {
  background-color: white;
  border-radius: 5px;
  padding: 7px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  object-fit: cover;
}

.fit {
  object-fit: cover;
}

.no-margin {
  margin: 0px;
}

.link {
  color: var(--light-font-color-hover) !important;
}

.link:hover {
  cursor: pointer;
  opacity: .8;
}

.about-img-music:hover {
  cursor: pointer;
}

.about-img-team:hover {
  cursor: pointer;
}

/* Small devices (landscape phones, 575.99px and down) */
@media (max-width: 575.99px) {
  .about-container {
    width: 100%;
  }

  .about-img {
    width: 48%;
  }

  .about-img-music {
    width: 48%;
  }

  .about-img-team {
    width: 48%;
    height: 190px;
  }
}

/* Small devices (landscape phones, 576px through 767.99px) */
@media (min-width: 576px) and (max-width: 767.99px) {
  .about-container {
    width: 100%;
  }

  .about-img {
    width: 48%;
  }

  .about-img-music {
    width: 48%;
  }

  .about-img-team {
    width: 48%;
    height: 190px;
  }
}

/* Medium devices (tablets, 768px through 991.99px) */
@media (min-width: 768px) and (max-width: 991.99px) {
  .about-container {
    width: 100%;
  }

  .about-img {
    width: 23%;
  }

  .about-img-music {
    width: 32%;
  }

  .about-img-team {
    width: 32%;
    height: 200px;
  }
}

/* Large devices (desktops, 992px through 1199.99px) */
@media (min-width: 992px) and (max-width: 1199.99px) {
  .about-container {
    width: 80%;
    margin-left: 10%;
  }

  .about-img {
    width: 23%;
  }

  .about-img-music {
    width: 32%;
  }

  .about-img-team {
    width: 32%;
    height: 200px;
  }
}

/* Extra large devices (large desktops, 1200px through 1599.99px) */
@media (min-width: 1200px) and (max-width: 1599.99px) {
  .about-container {
    width: 75%;
    margin-left: 12.5%;
  }

  .about-img {
    width: 23%;
  }

  .about-img-music {
    width: 15%;
  }

  .about-img-team {
    width: 15%;
  }
}

/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
  .about-container {
    width: 70%;
    margin-left: 15%;
  }

  .about-img {
    width: 23%;
  }

  .about-img-music {
    width: 15%;
  }

  .about-img-team {
    width: 15%;
  }
}
