/* common styles for landing container regardless of screen size */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-image {
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid white;
}

.landing-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-icon-light {
  width: 40px;
  height: 40px;
  margin: 0px 10px 0px 10px;
  color: black;
}

.landing-icon-dark {
  width: 40px;
  height: 40px;
  margin: 0px 10px 0px 10px;
}

.landing-company-light:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.landing-company-dark:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.landing-icon-light:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.landing-icon-dark:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.landing-name-light {
  margin-bottom: 15px;
}

.landing-name-dark {
  margin-bottom: 15px;
}

.landing-description-light {
  margin-top: 15px;
}

.landing-description-dark {
  margin-top: 15px;
}

/* Small devices (landscape phones, 768.99px and down) */
@media (max-width: 768.99px) {
  .landing-image-container {
    width: 300px;
    height: 300px;
  }

  .landing-image {
    height: 300px;
    width: 300px;
  }
}

/* All other devices (landscape phones, 769px and up) */
@media (min-width: 769px) {
  .landing-image-container {
    width: 350px;
    height: 350px;
  }

  .landing-image {
    width: 350px;
    height: 350px;
  }
}
