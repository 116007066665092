/* common styles for header container regardless of screen size */
.header-container {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
}

.header-left {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

.header-item {
  list-style-type: none;
  margin: 0px 5px 0px 5px;
  font-weight: bold;
}

.header-name:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}

.header-item:hover {
  cursor: pointer;
  color: var(--light-font-color-hover);
}


.header-logo:hover {
  cursor: pointer;
  color: black;
}

@keyframes header-icon-key {
  100% {
    color: #85bb65;
  }
}

/* Small devices (landscape phones, 575.99px and down) */
@media (max-width: 575.99px) {
  .header-right {
    width: 325px;
  }

  .header-logo {
    height: 3.5rem;
    width: 3.5rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Small devices (landscape phones, 576px through 767.99px) */
@media (min-width: 576px) and (max-width: 767.99px) {
  .header-right {
    width: 350px;
  }

  .header-logo {
    height: 4rem;
    width: 4rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Medium devices (tablets, 768px through 991.99px) */
@media (min-width: 768px) and (max-width: 991.99px) {
  .header-right {
    width: 375px;
  }

  .header-logo {
    height: 4.5rem;
    width: 4.5rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Large devices (desktops, 992px through 1199.99px) */
@media (min-width: 992px) and (max-width: 1199.99px) {
  .header-right {
    width: 400px;
  }

  .header-logo {
    height: 4.5rem;
    width: 4.5rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Extra large devices (large desktops, 1200px through 1599.99px) */
@media (min-width: 1200px) and (max-width: 1599.99px) {
  .header-right {
    width: 425px;
  }

  .header-logo {
    height: 4.5rem;
    width: 4.5rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
  .header-right {
    width: 450px;
  }

  .header-logo {
    height: 4.5rem;
    width: 4.5rem;
  }

  .header-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
