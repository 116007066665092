.App {
  text-align: center;
}

.App-body {
  margin-top: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

p {
  font-size: 1.1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.35rem;
}

:root {
  /* --font-family-sans-serif: "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
  --light-font-color-hover: #85bb65;
}

.dark {
  background-color: #333;
  color: #fff;
}

.light {
  background-color: #fff;
  color: #333;
}

button:hover {
  cursor: pointer;
}

a:link, a:visited {
  text-decoration: none;
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
